import React, { useState } from "react";

import { Link } from "react-router-dom";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';

import toast, { Toaster } from "react-hot-toast";
import Header from "../common/Header";

import LoadingOverlay from "react-loading-overlay";
import DropUploadZone from "../subcompo/DropUploadZone";
import ToggleTab from "../subcompo/DashboardTable/ToggleTab";

import { Form } from "react-bootstrap";

import axios from "axios";
import { awsUrl2 } from "../../config";

const moment = require("moment");
const uuidv4 = require("uuid/v4");
const ValidateDashboard = () => {
  const [companyfiles, setCompanyFiles] = useState();
  const [contactfiles, setContactFiles] = useState();
  const [payrollEmodfiles, setPayrollEmodFiles] = useState();
  const [statusfiles, setStatusFiles] = useState();
  const [loaderCompany, setLoaderCompany] = useState(false);
  const [loaderContact, setLoaderContact] = useState(false);
  const [loaderPayrollEmod, setLoaderPayrollEmod] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState("");
  const [isMoreThanThousandEmp, setIsMoreThanThousandEmp] = useState(false);

  const warningAlert = () => toast.error("Please select file.");
  const errorAlert = () => toast.error("Error in uploading.");
  const successAlert = () =>
    toast.success("Successfully Uploaded in bucket, ready for validations now");
  // const wentWrong = () => toast.error("Something went wrong, Please try again later.");
  const errorfile = () =>
    toast.error("Validation Errors !! Error file is generated");
  const success = () => toast.success("Successfully Validated and DB updated");

  const handleUploadCompany = async (action, object_type) => {
    try {
      if (companyfiles) {
        setLoaderCompany(true);
        let record_number = uuidv4();
        let getSignedURLParams = {
          fileType: "csv",
          fileName: companyfiles.name,
          folderName: record_number,
        };
        let res = await axios.post(
          awsUrl2 + "/api/excelFileValidator/getSignedURL",
          JSON.stringify(getSignedURLParams)
        );
        let lossSignedURL = res.data.lossSignedURL;
        let options = {
          header: { "Content-Type": "csv" },
        };
        await axios.put(lossSignedURL, companyfiles, options);
        let body = {
          record_number,
          object_type: object_type,
          file_name: companyfiles.name,
          file_status: "In Progress",
          file_action: action,
          upload_date: moment().format("x"),
          instance: selectedInstance,
          isMoreThanThousandEmp: isMoreThanThousandEmp === "true" ? true : false
        };
        await axios.post(
          awsUrl2 + "/api/excelFileValidator/insert",
          JSON.stringify(body)
        );
        setCompanyFiles("");
        successAlert();

        let lookTable = true;
        while (lookTable) {
          axios
            .post(awsUrl2 + "/api/excelFileValidator/get", JSON.stringify(body))
            .then((res) => {
              let statusFromDB = res.data.file_status;
              if (statusFromDB !== "In Progress") {
                lookTable = false;
                if (statusFromDB === "Success") success();
                else if (statusFromDB === "Error") errorfile();
                setLoaderCompany(false);
              }
            });
          await new Promise((resolve, reject) => {
            try {
              setTimeout(() => {
                resolve();
              }, 5000);
            } catch (error) {
              reject();
            }
          });
        }
      } else warningAlert();
    } catch (error) {
      errorAlert();
    }
  };
  const handleUploadContact = async (action, object_type) => {
    try {
      if (contactfiles) {
        setLoaderContact(true);
        let record_number = uuidv4();
        let getSignedURLParams = {
          fileType: "csv",
          fileName: contactfiles.name,
          folderName: record_number,
        };
        let res = await axios.post(
          awsUrl2 + "/api/excelFileValidator/getSignedURL",
          JSON.stringify(getSignedURLParams)
        );
        let lossSignedURL = res.data.lossSignedURL;
        let options = {
          header: { "Content-Type": "csv" },
        };
        await axios.put(lossSignedURL, contactfiles, options);
        let body = {
          record_number,
          object_type: object_type,
          file_name: contactfiles.name,
          file_status: "In Progress",
          file_action: action,
          upload_date: moment().format("x"),
          instance: selectedInstance,
          isMoreThanThousandEmp: isMoreThanThousandEmp === "true" ? true : false
        };
        await axios.post(
          awsUrl2 + "/api/excelFileValidator/insert",
          JSON.stringify(body)
        );
        setContactFiles("");
        successAlert();

        let lookTable = true;
        while (lookTable) {
          axios
            .post(awsUrl2 + "/api/excelFileValidator/get", JSON.stringify(body))
            .then((res) => {
              let statusFromDB = res.data.file_status;
              if (statusFromDB !== "In Progress") {
                lookTable = false;
                if (statusFromDB === "Success") success();
                else if (statusFromDB === "Error") errorfile();
                setLoaderContact(false);
              }
            });
          await new Promise((resolve, reject) => {
            try {
              setTimeout(() => {
                resolve();
              }, 5000);
            } catch (error) {
              reject();
            }
          });
        }
      } else warningAlert();
    } catch (error) {
      errorAlert();
    }
  };
  const handleUploadPayrollEmod = async (action, object_type) => {
    try {
      if (payrollEmodfiles) {
        setLoaderPayrollEmod(true);
        let record_number = uuidv4();
        let getSignedURLParams = {
          fileType: "csv",
          fileName: payrollEmodfiles.name,
          folderName: record_number,
        };
        let res = await axios.post(
          awsUrl2 + "/api/excelFileValidator/getSignedURL",
          JSON.stringify(getSignedURLParams)
        );
        let lossSignedURL = res.data.lossSignedURL;
        let options = {
          header: { "Content-Type": "csv" },
        };
        await axios.put(lossSignedURL, payrollEmodfiles, options);
        let body = {
          record_number,
          object_type: object_type,
          file_name: payrollEmodfiles.name,
          file_status: "In Progress",
          file_action: action,
          upload_date: moment().format("x"),
          instance: selectedInstance,
          isMoreThanThousandEmp: isMoreThanThousandEmp === "true" ? true : false
        };
        await axios.post(
          awsUrl2 + "/api/excelFileValidator/insert",
          JSON.stringify(body)
        );
        console.log("Table changed", res);
        setPayrollEmodFiles("");
        successAlert();

        let lookTable = true;
        while (lookTable) {
          axios
            .post(awsUrl2 + "/api/excelFileValidator/get", JSON.stringify(body))
            .then((res) => {
              let statusFromDB = res.data.file_status;
              if (statusFromDB !== "In Progress") {
                lookTable = false;
                if (statusFromDB === "Success") success();
                else if (statusFromDB === "Error") errorfile();
                setLoaderPayrollEmod(false);
              }
            });
          await new Promise((resolve, reject) => {
            try {
              setTimeout(() => {
                resolve();
              }, 5000);
            } catch (error) {
              reject();
            }
          });
        }
      } else warningAlert();
    } catch (error) {
      errorAlert();
    }
  };
  const handleUploadStatus = async (action, object_type) => {
    try {
      if (statusfiles) {
        setLoaderStatus(true);
        let record_number = uuidv4();
        let getSignedURLParams = {
          fileType: "csv",
          fileName: statusfiles.name,
          folderName: record_number,
        };
        let res = await axios.post(
          awsUrl2 + "/api/excelFileValidator/getSignedURL",
          JSON.stringify(getSignedURLParams)
        );
        let lossSignedURL = res.data.lossSignedURL;
        let options = {
          header: { "Content-Type": "csv" },
        };
        await axios.put(lossSignedURL, statusfiles, options);
        let body = {
          record_number,
          object_type: object_type,
          file_name: statusfiles.name,
          file_status: "In Progress",
          file_action: action,
          upload_date: moment().format("x"),
          instance: selectedInstance,
          isMoreThanThousandEmp: isMoreThanThousandEmp === "true" ? "true" : "false"
        };
        await axios.post(
          awsUrl2 + "/api/excelFileValidator/insert",
          JSON.stringify(body)
        );
        console.log("Table changed", res);
        setStatusFiles("");
        successAlert();

        let lookTable = true;
        while (lookTable) {
          axios
            .post(awsUrl2 + "/api/excelFileValidator/get", JSON.stringify(body))
            .then((res) => {
              let statusFromDB = res.data.file_status;
              if (statusFromDB !== "In Progress") {
                lookTable = false;
                if (statusFromDB === "Success") success();
                else if (statusFromDB === "Error") errorfile();
                setLoaderStatus(false);
              }
            });
          await new Promise((resolve, reject) => {
            try {
              setTimeout(() => {
                resolve();
              }, 5000);
            } catch (error) {
              reject();
            }
          });
        }
      } else warningAlert();
    } catch (error) {
      errorAlert();
    }
  };
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="d-flex header-container border-bottom">
        <Header />
      </div>
      <div>
        <button
          className="float-right mt-2 mb-2 mr-3"
          style={{
            fontSize: "16px",
            fontFamily: "Montserrat",
            backgroundColor: "#ffd13f",
            border: "none",
          }}
        >
          <Link
            to="/adminDashboard"
            style={{ color: "black", textDecoration: "none" }}
          >
            {" "}
            Admin Dashboard
          </Link>
        </button>
      </div>
      <div className="d-flex justify-content-center">
        <Link target="_blank" to="/NewDomain">
          New Domains in last 30 days without company profile data
        </Link>
      </div>
      <div className="d-flex justify-content-around heading">
        {" "}
        UPLOAD DASHBOARD
      </div>
      <div className="row mt-2 mb-5 justify-content-center">
        <DropdownButton
          bsStyle={selectedInstance || "instance"}
          title={selectedInstance || "Select Instance"}
          id={`dropdown-instance`}
          onSelect={e => setSelectedInstance(e)}
        >
          <Dropdown.Item eventKey="Fourth">Fourth</Dropdown.Item>
          <Dropdown.Item eventKey="Engage">Engage</Dropdown.Item>
        </DropdownButton>
      </div>
      {"Engage" === selectedInstance && <div className="row mt-2 mb-5 justify-content-center">
        <span className="mr-2">{"1000+ Employees?"}</span>
        <Form.Check
          inline
          id={`1200`}
          value="true"
          name={"more_than_thousand_employees"}
          checked={isMoreThanThousandEmp === "true" ? true : false}
          label="Yes"
          type="radio"
          onChange={(e) => setIsMoreThanThousandEmp("true")}
        />
        <Form.Check
          inline
          id={`1200`}
          value="false"
          name={"more_than_thousand_employees"}
          checked={isMoreThanThousandEmp === "false" || !isMoreThanThousandEmp ? true : false}
          label="No"
          type="radio"
          onChange={(e) => setIsMoreThanThousandEmp("false")}
        />
      </div>}
      <div className="row">
        <div className="d-flex flex-column col-md-12 col-lg-6">
          <LoadingOverlay active={loaderCompany} spinner>
            {/* <UploadField filename="Company Profile" setFiles={setCompanyFiles} files={companyfiles} /> */}
            <DropUploadZone
              fileType="Company Profile"
              setFiles={setCompanyFiles}
            />
            <div className="container d-flex justify-content-around button-padding ">
              <button
                type="button"
                className="btn btn-color upload-color"
                onClick={() => handleUploadCompany("Upload", "companyProfile")}
                disabled={selectedInstance ? false : true}
              >
                Upload
              </button>
              <button
                type="button"
                className="btn btn-primary update-color"
                onClick={() => handleUploadCompany("Update", "companyProfile")}
                disabled={selectedInstance ? false : true}
              >
                Update
              </button>
            </div>
          </LoadingOverlay>
        </div>

        <div className="d-flex flex-column col-md-12 col-lg-6">
          <LoadingOverlay active={loaderContact} spinner>
            {/* <UploadField filename="Contact Information" setFiles={setContactFiles} /> */}
            <DropUploadZone
              fileType="Contact Information"
              setFiles={setContactFiles}
            />
            <div className="container d-flex justify-content-around button-padding ">
              <button
                disabled={selectedInstance ? false : true}
                type="button"
                className="btn btn-color upload-color"
                onClick={() => handleUploadContact("Upload", "contactInfo")}
              >
                Upload
              </button>
              <button
                type="button"
                disabled
                className="btn btn-primary update-color"
                onClick={() => handleUploadContact("Update", "contactInfo")}
              >
                Update
              </button>
              <button
                type="button"
                disabled
                className="btn btn-color replace-color"
                onClick={() => handleUploadContact("Replace", "contactInfo")}
              >
                Replace
              </button>
            </div>
          </LoadingOverlay>
        </div>
      </div>

      <div className="row mt-5">
        <div className="d-flex flex-column col-md-12 col-lg-6">
          <LoadingOverlay active={loaderPayrollEmod} spinner>
            {/* <UploadField filename="Payroll Emod Information" setFiles={setPayrollEmodFiles} /> */}
            <DropUploadZone
              fileType="Payroll Emod Information"
              setFiles={setPayrollEmodFiles}
            />
            <div className="container d-flex justify-content-around button-padding">
              <button
                disabled={selectedInstance ? false : true}
                type="button"
                className="btn btn-color upload-color"
                onClick={() => handleUploadPayrollEmod("Upload", "payrollEmod")}
              >
                Upload
              </button>
              <button
                type="button"
                disabled
                className="btn btn-primary update-color"
                onClick={() => handleUploadPayrollEmod("Update", "payrollEmod")}
              >
                Update
              </button>
              <button
                type="button"
                disabled="true"
                className="btn btn-color replace-color"
                onClick={() =>
                  handleUploadPayrollEmod("Replace", "payrollEmod")
                }
              >
                Replace
              </button>
            </div>
          </LoadingOverlay>
        </div>

        <div className="d-flex flex-column col-md-12 col-lg-6">
          <LoadingOverlay active={loaderStatus} spinner>
            {/* <UploadField filename="Status" setFiles={setStatusFiles} /> */}
            <DropUploadZone fileType="Status" setFiles={setStatusFiles} />
            <div className="container d-flex justify-content-around button-padding ">
              <button
                disabled={selectedInstance ? false : true}
                type="button"
                className="btn btn-color upload-color"
                onClick={() => handleUploadStatus("Upload", "status")}
              >
                Upload
              </button>
            </div>
          </LoadingOverlay>
        </div>
      </div>

      <div className="ToggleDiv">
        <button
          type="button"
          className="btn btn-color update-color"
          onClick={() => setRefreshData(true)}
        >
          Refresh Data
        </button>
        <LoadingOverlay active={refreshData} spinner>
          <ToggleTab
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
        </LoadingOverlay>
      </div>
    </>
  );
};

export default ValidateDashboard;
